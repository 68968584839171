import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import _ from 'lodash';
import SacredBig from '../../assets/svg/sacred-print.svg';
import useResponsive from '../../lib/useResponsive';
import { AppContext } from '../../lib/context';
import styles from './layout.module.scss';
import Avatar from '../avatar/avatar';

const logoCycle = [
  '/svg/sacred-fig-small1.svg',
  '/svg/sacred-fig-small2.svg',
  '/svg/sacred-fig-small3.svg',
  '/svg/sacred-fig-small4.svg',
];

const Header = ({ style: parentStyle, logo, lightTitle = false }) => {
  const { isMobile, isDesktop } = useResponsive();
  const router = useRouter();

  const { isAuthorized, user, loading } = useContext(AppContext);

  const [isSign, setIsSign] = useState(false);

  const [logoIdx, setLogoIdx] = useState(0);

  useEffect(() => {
    setIsSign(
      window.location.pathname.includes('signin') ||
        window.location.pathname.includes('signup') ||
        window.location.pathname.includes('profile'),
    );
  }, []);

  const onFocusLogo = () => {
    setLogoIdx((logoIdx + 1) % 4);
  };

  const renderMobileLogoBar = () => {
    return (
      <div className={styles.logobar}>
        {logo && (
          <div className={styles.logo}>
            <Link href="/">
              <a>
                <img src={SacredBig} alt="The Sacred Fig" width="120" />
              </a>
            </Link>
          </div>
        )}

        {/*{!isSign &&*/}
        {/*  !loading &&*/}
        {/*  (isAuthorized ? (*/}
        {/*    <div className={styles.login}>*/}
        {/*      <Link href="/profile">*/}
        {/*        <a style={{ alignItems: "center" }}>*/}
        {/*          Profile*/}
        {/*          <Avatar*/}
        {/*            avatar={user?.avatar}*/}
        {/*            size="small"*/}
        {/*            style={{ marginLeft: 10 }}*/}
        {/*          ></Avatar>*/}
        {/*        </a>*/}
        {/*      </Link>*/}
        {/*    </div>*/}
        {/*  ) : (*/}
        {/*    <div className={styles.login}>*/}
        {/*      <Link*/}
        {/*        href={`/signin?redirect=${encodeURIComponent(router.asPath)}`}*/}
        {/*      >*/}
        {/*        <a>Log In</a>*/}
        {/*      </Link>*/}
        {/*    </div>*/}
        {/*  ))}*/}
      </div>
    );
  };

  const renderDesktopLogo = () => {
    return (
      <>
        {/*<div className={styles.loginLink}>*/}
        {/*  {!isSign &&*/}
        {/*    !loading &&*/}
        {/*    (isAuthorized ? (*/}
        {/*      <>*/}
        {/*        <Link href="/profile">*/}
        {/*          <a style={{ alignItems: 'center' }} style={{ color: lightTitle && 'white' }}>*/}
        {/*            Profile*/}
        {/*            <Avatar avatar={user?.avatar} size="small" style={{ marginLeft: 20 }}></Avatar>*/}
        {/*          </a>*/}
        {/*        </Link>*/}
        {/*      </>*/}
        {/*    ) : (*/}
        {/*      <Link href={`/signin?redirect=${encodeURIComponent(router.asPath)}`}>Log In</Link>*/}
        {/*    ))}*/}
        {/*</div>*/}
        <div className={styles.subpageLogo}>
          {logo && (
            <Link href="/">
              <a>
                <img
                  src={logoCycle[logoIdx]}
                  alt="The Sacred Fig"
                  width="75"
                  className={styles.tsfLogo}
                  style={{ filter: lightTitle && 'invert(100%)' }}
                  onMouseEnter={onFocusLogo}
                />
              </a>
            </Link>
          )}
        </div>
      </>
    );
  };

  const isActiveLink = (link) => (router.pathname.split('/')[1] === link ? styles.active : '');

  return (
    <div className={styles.header}>
      {isMobile && renderMobileLogoBar()}

      <div className={styles.navBar}>
        <div className={styles.mainLinks}>
          <Link href="/trainings">
            <a className={`${styles.first} ${isActiveLink('trainings')}`} style={{ color: lightTitle && 'white' }}>
              Yoga Teacher Trainings
            </a>
          </Link>
          <Link href="/retreats">
            <a className={`${styles.second} ${isActiveLink('retreats')}`} style={{ color: lightTitle && 'white' }}>
              Retreats
            </a>
          </Link>
          <Link href="https://www.figflix.com/">
            <a className={styles.third} style={{ color: lightTitle && 'white' }}>
              FigFlix
            </a>
          </Link>
        </div>

        {isDesktop && renderDesktopLogo()}
      </div>
    </div>
  );
};

export default Header;
