import { getStrapiMedia } from "../../lib/utils";
import styles from "./avatar.module.scss";

export default function Avatar({
  text,
  src,
  avatar,
  style: parentStyle,
  size = "small",
  border = false,
  onClick,
}) {
  return (
    <div
      className={`${styles.avatar} ${
        size === "small" ? styles.small : styles.big
      }`}
      style={{ ...parentStyle, cursor: onClick && "pointer" }}
      onClick={onClick}
    >
      {text ? (
        <div className={styles.text}>{text}</div>
      ) : (
        <div
          style={{
            backgroundPosition: "center",
            backgroundSize: "cover",
            width: "100%",
            height: "100%",
            backgroundImage: src
              ? `url(${src})`
              : avatar?.url
              ? `url(${getStrapiMedia(avatar)})` // ?${new Date().getTime()}, for rerender image of same url
              : "url('/svg/default-avatar.svg')",
          }}
        ></div>
      )}
    </div>
  );
}
