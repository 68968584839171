import { useContext, useEffect, useState } from "react";
import Link from "next/link";
import { getStrapiMedia } from "../../lib/utils";
import Footer from "./footer";
import Header from "./header";
import styles from "./layout.module.scss";
import SacredPrint from "../../assets/svg/sacred-print.svg";
import FigFlixPrint from "../../assets/svg/fig-flix.svg";
import { AppContext } from "../../lib/context";
import { useRouter } from "next/router";

const Layout = ({
  children,
  logoType = "big",
  title,
  background,
  style: parentStyle,
  showJoinMail = true,
  showFooter = true,
  lightTitle = false,
  bookNow,
}) => {
  const [ visible, setVisible ] = useState(true)
  const backgroundImg = background ? getStrapiMedia(background) : "";
  const router = useRouter();

  useEffect(() => {
    const handleStart = () => {
      setVisible(false)
    };
    const handleStop = () => {
      setVisible(true)
    };

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleStop);
    router.events.on("routeChangeError", handleStop);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleStop);
      router.events.off("routeChangeError", handleStop);
    };
  }, [router]);

  const [isFigFlix, setIsFigFlix] = useState(false);

  useEffect(() => {
    setIsFigFlix(window.location.pathname.includes("/figflix"));
  }, []);

  return (
    <>
      {background ? (
        <div className={styles.root} style={parentStyle}>
          <div
            className={`${styles.background} ${visible ? styles.fadein : styles.fadeout}`}
            style={{
              backgroundImage: `url(${backgroundImg})`,
            }}
          ></div>

          <div className={styles.absoluteTop}>
            <Header logo={logoType === "small"} lightTitle={lightTitle} />
            {logoType === "big" && (
              <Link href="/">
                {isFigFlix ? (
                  <div className={styles.sacredPrint}>
                    <img src={FigFlixPrint} alt="FigFlix" width="60%"></img>
                  </div>
                ) : (
                  <div className={styles.sacredPrint}>
                    <img
                      src={SacredPrint}
                      alt="The Sacred Fig"
                      width="100%"
                    ></img>
                  </div>
                )}
              </Link>
            )}
            {title && <h1 className={styles.title} style={{color: lightTitle && 'white'}}>{title}</h1>}
          </div>

          <div className={styles.container}>{children}</div>

          {showFooter && <Footer showJoinMail={showJoinMail} />}
        </div>
      ) : (
        <div className={styles.root} style={parentStyle}>
          <div className={styles.top}>
            <Header logo={logoType === "small"} lightTitle={lightTitle} />
          </div>
          {logoType === "big" && (
            <Link href="/">
              {isFigFlix ? (
                <div className={styles.sacredPrint}>
                  <img src={FigFlixPrint} alt="FigFlix" width="60%"></img>
                </div>
              ) : (
                <div className={styles.sacredPrint}>
                  <img
                    src={SacredPrint}
                    alt="The Sacred Fig"
                    width="100%"
                  ></img>
                </div>
              )}
            </Link>
          )}
          {title && <h1 className={`${styles.title} ${visible ? styles.fadein : styles.fadeout}`}>{title}</h1>}
          <main>
            <div className={`${styles.container} ${visible ? styles.fadein : styles.fadeout}`}>
              <article>
                {children}
              </article>
            </div>
          </main>

          {showFooter && <Footer className={`${visible ? styles.fadein : styles.fadeout}`} showJoinMail={showJoinMail} />}
        </div>
      )}

      {bookNow}
    </>
  );
};

export default Layout;
