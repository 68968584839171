import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Link from "next/link";
import styles from "./layout.module.scss";
import logo from "../../assets/svg/logo.svg";
import useResponsive from "../../lib/useResponsive";
import { fetchNextAPI } from "../../lib/api";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import MailchimpSubscribe from "react-mailchimp-subscribe"

const Footer = ({ showJoinMail }) => {
  const navList = [
    {
      href: "/trainings",
      name: "Yoga Teacher Trainings",
    },
    {
      href: "/blog",
      name: "The Fig Feed",
    },
    {
      href: "/profile",
      name: "Profile",
    },
    {
      href: "/retreats",
      name: "Retreats",
    },
    {
      href: "/about",
      name: "Our Story",
    },
    {
      href: "https://www.instagram.com/thesacredfig/",
      name: "Instagram",
    },
    {
      href: "https://www.figflix.com/",
      name: "FigFlix",
    },
    {
      href: "/about/teachers",
      name: "Teachers",
    },
    {
      href: "https://www.youtube.com/channel/UCv-RAQRCUE6ymk_EHnLQZIw",
      name: "YouTube",
    },
    {
      href: "",
      name: "",
    },
    {
      href: "/refund-policy",
      name: "Refund Policy",
    },
    {
      href: "/contact",
      name: "Contact",
    },
  ];

  const navListMobile = [
    {
      href: "/trainings",
      name: "Teacher Trainings",
    },
    {
      href: "/profile",
      name: "Profile",
    },
    {
      href: "/retreats",
      name: "Retreats",
    },
    {
      href: "/about/teachers",
      name: "Teachers",
    },
    {
      href: "https://www.figflix.com/",
      name: "FigFlix",
    },
    {
      href: "/contact",
      name: "Contact",
    },
    {
      href: "/refund-policy",
      name: "Refund Policy",
    },
    {
      href: "/blog",
      name: "The Fig Feed",
    },
    {
      href: "https://www.instagram.com/thesacredfig/",
      name: "Instagram",
    },
    {
      href: "/about",
      name: "Our Story",
    },
    {
      href: "https://www.youtube.com/channel/UCv-RAQRCUE6ymk_EHnLQZIw",
      name: "YouTube",
    },
  ];

  const { isDesktop } = useResponsive();

  const uri = 'https://thesacredfig.us7.list-manage.com/subscribe/post?u=bf0d66d8af41484bffd7b21b9&amp;id=69d222b68b'

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [ modalOpen, setModalOpen ] = useState(false);

  return (
    <MailchimpSubscribe
      url={uri}
      render={({ subscribe, status, message }) => {

        if( modalOpen && (status === "success" || status === "error")) {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className={styles.customUi}>
                  <h1>{status}</h1>
                  <div dangerouslySetInnerHTML={{ __html: message }} />
                  <button onClick={()=>{
                    setModalOpen(false)
                    reset()
                    onClose()
                  }}>
                    Close
                  </button>
                </div>
              );
            }
          });
        }

        const send = async (data) => {
          const res = await subscribe(data)
          setModalOpen(true)
        }

        return (
          <div className={styles.footer}>
            {showJoinMail && (
              <form className={styles.mailinglist} onSubmit={handleSubmit(send)}>
                {isDesktop ? (
                  <div className={styles.formgroup}>
                    <label className={styles.item} style={{marginRight: '100px'}}>Join the Fig Family</label>
                    <div>
                      <input
                        className={styles.item}
                        type="email"
                        placeholder="email"
                        disabled={status === "sending"}
                        style={{ borderBottom: "1px solid #fff" }}
                        {...register('EMAIL')}
                      />
                      <button disabled={status === "sending"} onClick={handleSubmit(send)} className={`${styles.item} ${styles.join}`}>
                        <img src="/svg/arrow-right.svg" width="90px"></img>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className={styles.formgroup}>
                    <label className={styles.item} style={{marginRight: '100px'}}>The Fig Family</label>
                    <div className={`${styles.item} ${styles.mailbox}`}>
                      <input 
                        type="email" 
                        placeholder="email" 
                        disabled={status === "sending"}
                        {...register('EMAIL')}
                      />
                      <button onClick={handleSubmit(send)} type="submit" className={styles.join} disabled={status === "sending"}>
                        <img src="/svg/arrow-right.svg" width="30px"></img>
                      </button>
                    </div>
                  </div>
                )}
              </form>
            )}

            <div className={styles.content}>
              <div className={styles.group}>
                {isDesktop
                  ? navList.map((nav, i) => (
                      <Link href={nav.href} key={i}>
                        {nav.name}
                      </Link>
                    ))
                  : navListMobile.map((nav, i) => (
                      <Link href={nav.href} key={i}>
                        {nav.name}
                      </Link>
                    ))}
              </div>

              <div className={styles.logo}>
                <img src={logo} alt="footer-Logo" width="90px"></img>
              </div>

              <div className={`${styles.group} ${styles.bottom}`}>
                {isDesktop ? (
                  <>
                    <p>Yoga Alliance Member</p>
                    <p>Made with love in NYC</p>
                    <p>(C) The Sacred Fig.</p>
                  </>
                ) : (
                  <>
                    <p>Yoga Alliance Member</p>
                    <p>(C) The Sacred Fig.</p>
                  </>
                )}
              </div>
            </div>
          </div>
        )
      }}
    />
    
  );
};

export default Footer;
