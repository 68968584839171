import { parseCookies } from "nookies";

export function getStrapiURL(path = "") {
  return `${
    process.env.NEXT_PUBLIC_STRAPI_API_URL || "http://localhost:1337"
  }${path}`;
}

// Helper to make GET requests to Strapi
export async function fetchAPI(path, opts, ctx = null) {
  const requestUrl = getStrapiURL(path);

  const jwt = parseCookies(ctx).jwt;
  const promise = fetch(requestUrl, {
    headers: jwt
      ? {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        }
      : {
          "Content-Type": "application/json",
        },
    ...opts,
  }).then((res) => res.json());

  return promise;
}

// Helper to make GET requests to Strapi
export async function fetchFormDataAPI(path, opts, ctx = null) {
  const requestUrl = getStrapiURL(path);

  const jwt = parseCookies(ctx).jwt;
  const promise = fetch(requestUrl, {
    headers: jwt
      ? {
          Authorization: `Bearer ${jwt}`,
        }
      : {},
    ...opts,
  }).then((res) => res.json());

  return promise;
}

export async function fetchNextAPI(path, opts) {
  const promise = fetch(path, {
    ...opts,
  }).then((res) => res.json());

  return promise;
}
