import { useEffect } from "react";
import { getStrapiURL } from "./api";

export function getStrapiMedia(media) {
  if (!media) {
    return "";
  }
  
  const imageUrl = media.url.startsWith("/")
    ? getStrapiURL(media.url)
    : media.url;
  return imageUrl;
}

export function importScript(resourceUrl) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = resourceUrl;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [resourceUrl]);
}
